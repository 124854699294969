/**
Author - Venkatesh 😁
Git - https://github.com/venkatesh1146
**/

import React, {
    useState, useEffect
} from "react";

import styled from "styled-components";


import {
    handleApiError
} from "helpers";

import {
    Spinner
} from "../commons";

import FATCAForm from "./FATCAForm";
import {
 getFATCAFormSchema,
  updateFATCADetails
} from "api/rest";

const FATCAFormContainer = ({
    updateStatus
}) => {
    const [formSchema, setFormSchema] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchSchema();
    }, []);

    const fetchSchema = async () => {
        try {
            setIsLoading(true);
            const response = await getFATCAFormSchema();
            setFormSchema(response.data);
        } catch (error) {
            handleApiError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFormSubmit = async (data) => {
        try {
            setIsLoading(true);
            const payload = {
                ...data,
                mothers_name: data.mothers_name?.toUpperCase(),
                fathers_name: data.fathers_name?.toUpperCase(),
                full_name: data.full_name?.toUpperCase()
            };
            const res = await updateFATCADetails(payload);
            if (res.status == 200) {
                updateStatus();
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            setIsLoading(false);
        };
    };

    return (
        isLoading ? (
            <LoaderContainer>
                <Spinner
                    color="blue"
                    message="Updating your information, Please do not close the window"
                />
            </LoaderContainer>
        ) : (
                <FATCAForm
                formSchema={formSchema}
                handleFormSubmit={handleFormSubmit}
            />
        )
    );
};

const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FATCAFormContainer;