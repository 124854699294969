/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  ActionButton
} from "screens/commons";
import {
  KycScreenMapping,
  KycFlowMapping
} from "types";
import IMAGES from "constants/Images";
import "./PartnerKyc.css";

const propTypes = {
  kycData: PropTypes.object.isRequired,
  handleDigioInitialization: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired
};

const PartnerKyc = ({
  kycData,
  handleDigioInitialization,
  handleGoBack
}) => {
  // const digiLocker = "https://i.wlycdn.com/wealthy-kyc/digi-locker.svg";
  const digiLocker = "https://wealthy-media-test.s3.amazonaws.com/wealthy-kyc/digi-locker.svg";
  const selfieUpload = "https://i.wlycdn.com/wealthy-kyc/selfie-upload.svg";
  // const panUpload = "https://i.wlycdn.com/wealthy-kyc/pan-upload.svg";
  const panUpload = "https://wealthy-media-test.s3.amazonaws.com/wealthy-kyc/upload-pan.svg";

  const {stage,flow} = kycData;

  let title = "DigiLocker KYC";
  let subTitle = "Redirecting to Digilocker";
  let description = "";
  let imgPath = digiLocker;
  let goBackButtonText = "Process Manually";
  let step="1";

  switch (stage) {
    case KycScreenMapping.DIGILOCKER:
      title = "1. DigiLocker KYC";
      subTitle = "Redirecting to Digilocker";
      description = "";
      imgPath = digiLocker;
      step= "1";
      break;
    case KycScreenMapping.UPLOAD_PAN:
      title = "Upload Pan";
      subTitle = "Upload PAN Card";
      description = "Upload the front of your PAN Card for verification";
      imgPath = panUpload;
      step= "2";
      break;
      case KycScreenMapping.SELFIE:
      title = "Upload Selfie";
      subTitle = "Upload Selfie";
      description = "Upload your selfie for verification";
      imgPath = selfieUpload;
      step= "3";
      break;
    default:
      title = "DigiLocker KYC";
      subTitle = "Redirecting to Digilocker";
      description = "";
      imgPath = digiLocker;
      break;
  }

  return (
    <Container>
      <KycWrapper>

        <SubHeading>Manual KYC</SubHeading>
        <TitleWrapper >
        <Title>{title}</Title>
        {/* <SubTitleWrapper>
          <SubTitle>Step {step}/3</SubTitle>
        </SubTitleWrapper> */}
      </TitleWrapper>
      <ContentWrapper>
        <Wrapper>
        <HeaderWrapper isDigilockerStage={stage === KycScreenMapping.DIGILOCKER}>

          {
            stage === KycScreenMapping.DIGILOCKER ?
              (
                <>
                <Subtitle>{subTitle}</Subtitle>

                <DigiLockerImage>
                  <img
                    alt={title}
                    className="kyc-image"
                    src={imgPath}
                  />
                </DigiLockerImage>
                </>
              ) : (
                <>
                <KycImage>
                  <img
                    alt={title}
                    className="kyc-image"
                    src={imgPath}
                  />
                </KycImage>
                <SecondarySubtitle>{subTitle}</SecondarySubtitle>
                <Description>{description}</Description>
                </>
              )}

        </HeaderWrapper>

          {
            stage === KycScreenMapping.DIGILOCKER &&
              (
                <NoteContainer>
                  <NoteTitle>
                    Important Pointers:
                  </NoteTitle>
                  <ul>
                  <NoteDescription>
                    Keep your Aadhar number and mobile
                    number linked with Aadhar handy.
                  </NoteDescription>
                  <NoteDescription>
                    You will receive OTP on this number.
                  </NoteDescription>
                  <NoteDescription>
                    If you haven't set up your DigiLocker PIN,
                    or if you've forgotten it, click on "Forgot
                    security PIN" on the DigiLocker KYC screen.
                  </NoteDescription>
                </ul>
                </NoteContainer>
              )}
        </Wrapper>
        <ActionButtonsWrapper>

        <ActionButton
          buttonText="Continue"
          callback={handleDigioInitialization}
          className="partner-continue-button"
        />
        {(stage === KycScreenMapping.DIGILOCKER && flow === KycFlowMapping.PARTNER) && (
          <>
            <ActionButton
              buttonText={goBackButtonText}
              callback={() => handleGoBack(flow)}
              additionalStyles={{
                background: "#FFFFFF",
                color: "rgb(103, 37, 244)",
                border: "2px solid rgb(103, 37, 244)",
                padding: "1.5rem"
              }}
            />
            <div style={{
              display: "grid",
              gridAutoFlow: "column",
              gap: "0.5rem",
              marginTop: "1rem",
              color: "#7E7E7E",
              alignItems: "center"
            }}>
            <img src={IMAGES.infoIcon} alt="info-icon" title="Choose Manual option after clicking this button" />
            <span>Incase of failure or face any issues with digilocker there is always options for upload your id proofs manually. 
              &nbsp;
            </span>
            </div>
          </>
        )}
        </ActionButtonsWrapper>

      </ContentWrapper>
      </KycWrapper>
    </Container>
  );
};

const ActionButtonsWrapper = styled.div`
  padding: 2rem
`;

const Container = styled.div`
  padding: 2.4rem 0;
  margin: 0 auto;
  height: calc(100dvh - 18rem);
  width: 90%;
`;

const KycWrapper = styled.div`
  display: grid;
  ${'' /* height: 80% */}
  
`;

const ContentWrapper= styled.div`
  background: #F8F4FF;
  border-radius: 0.75rem;
`;

const TitleWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin-bottom: 3rem;
  
`;


const SubHeading = styled.h3`
  margin: 0;
  padding: 0;
  color: #7E7E7E;
  font-weight: 400;
  ${'' /* font-size: 1rem; */}
  ${'' /* letter-spacing: 0.1rem; */}
  align-self: flex-start;
  margin-bottom: 1rem;
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  color: #000000;
  ${'' /* letter-spacing: 0.1rem; */}
  align-self: flex-start;
  margin-bottom: ${props => props.isMarginBelow ? '3rem' : '0rem'};
`;

const SubTitleWrapper = styled.div`
  margin: 0px;
  padding: 4px 8px;
  border-radius: 2rem;
  color: rgb(28, 28, 28);
  border: 1px solid rgb(217, 217, 217);
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 12px
`;

const SecondarySubtitle = styled.h2`
  font-weight: 400;
  text-align: center;
  font-size: 16px;
  font-weight: 500
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderWrapper = styled.div`
    display: grid;
    width: 100%;

  border-bottom: ${props => props.isDigilockerStage ? '1px solid #E6E6E6' : 'unset'};
  padding: 2rem;
  grid-auto-flow: ${props => props.isDigilockerStage ? 'column' : 'row'};
  justify-content: center;
  align-items: center

`;



// const Title = styled.h1.attrs({
//   className: 'primary-title'
// })`
//   margin: 0;
//   padding: 0;
//   color: #000000;
//   letter-spacing: 0.1rem;
//   align-self: start;
//   margin-bottom: 15rem;

// `;

const Subtitle = styled.p.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  color: #000000;
`;

const Description = styled.span.attrs({
  className: 'description2'
})`
  padding: 0;
  color: #7E7E7E;
  text-align: center;
  margin-bottom: 4rem;
`;

const NoteContainer = styled.div`
  ${'' /* background-color: #F9F9F9; */}
  border-raidus: 0.375rem;
  padding: 1.5rem;
  ${'' /* margin-top: 2rem; */}
  ${'' /* margin-bottom: 4rem; */}
`;

const NoteTitle = styled.div.attrs({
  className: 'description2'
})`
  color: #7E7E7E;
  font-size: 1.4rem;
  ${'' /* font-weight: 600 !important; */}
`;

const NoteDescription = styled.li.attrs({
  className: 'description2'
})`
  color: #1C1C1C;
  margin-top: 1rem;
`;

const KycImage = styled.div`
  width: 15rem;
  justify-self: center;
  margin: 5rem 0rem;

  .kyc-image {
    height: 100%;
    width: 100%;
    margin-left: -1rem;
  }
`;

const DigiLockerImage = styled.div`
  align-self: center;
  text-align: end;
  ${'' /* margin-bottom: 4rem; */}

  .kyc-image {
    width: 12rem;
  }
`;

PartnerKyc.propTypes = propTypes;

export default PartnerKyc;