/**
Author - Pradip Dhakal
Git - https://github.com/pradipwealthy
**/

import React from "react";
import PropTypes from "prop-types";
import UploadMultipleDocuments from "components/UploadMultipleDocument";

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleImageUpload: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  handleDeleteUploadedFile: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  idsMetaData:PropTypes.object.isRequired
};

const UploadIds = ({
  isLoading,
  isDisabled,
  handleImageUpload,
  uploadedFiles,
  handleDeleteUploadedFile,
  onSubmit,
  idsMetaData,
  selectedOption,
  setSelectedOption
}) => <UploadMultipleDocuments
        documents={[
          {
            "name": "front_side",
            "heading":"Officially Valid Documents",
            "subHeading": "Please select and upload relevant document",
            "dropzoneInstruction": "Drag 'n' drop the document here or click to select files",
            "group": "aadhaarcard",
            "onDrop": (file) => handleImageUpload(file, "front_side"),
            "multiple": false,
            "uploadMessage": "<b> Front side </b> : Drag 'n' drop the document here or click to select files",
            "documentOptions": idsMetaData.allowedIds,
            "selectedOption": selectedOption,
             "onChangeDocOption":setSelectedOption
          },
          {
            "name": "back_side",
            "group": "aadhaarcard",
            "dropzoneInstruction": "Drag 'n' drop the document here or click to select files",
            "onDrop": (file) => handleImageUpload(file, "back_side"),
            "multiple": false,
            "uploadMessage": "<b> Back side </b>: Drag 'n' drop the document here or click to select files"
          },
          {
            "name": "pan_card",
            "heading" :"Pan Card",
            "subHeading": "Please upload the clear image of your pan card",
            "group": "pancard",
            "dropzoneInstruction": "Drag 'n' drop the document here or click to select files",
            "onDrop": (file) => handleImageUpload(file, "pan_card"),
            "multiple": false,
            "uploadMessage": "<b> Pan Card </b> : Drag 'n' drop the document here or click to select files"
          },
        ]}
        showImageContainer={false}
        uploadedFiles={uploadedFiles}
        handleDeleteUploadedFile={handleDeleteUploadedFile}
        commonInstructions={
          `
          <br />
          ● Supported file formats include only png, jpg, jpeg and pdf.
          <br />● If both sides of the document are in single file please upload it on
          both front and back sides.
          <br />● If your documents are password protected, you can use <a style={{textDecoration: "none"}} href="https://tools.wealthy.systems/#/pdf/decode" target="_blank" rel="noreferrer">this tool</a> to unlock them.
          <br />● Please use <a style={{textDecoration: "none"}} href="https://docs.google.com/document/d/1MFD_amYN6cvvbr3IG0lsFozjpLwEaLfuSFtPC68l4vY/edit?usp=sharing" target="_blank" rel="noreferrer">this document</a> for guidelines.
          `
        }
        actionButton={{
          buttonText: "Upload & Continue",
          callback: onSubmit,
          isLoading: isLoading,
          isDisabled: isDisabled
        }}
    />;

UploadIds.propTypes = propTypes;

export default UploadIds;
