/**
Author - Venkatesh Pullaganti
Git - https://github.com/venkatesh1146
**/

import React, {
  useState, useEffect
} from "react";
import {
  PropTypes
} from "prop-types";
import IMAGES from "constants/Images";

import UploadDocumentContainer from "components/UploadDocument/UploadDocument.container";
import UploadPartnerDocumentContainer from "components/UploadPartnerDocument/UploadDocument.container";
import {
 getBankProofMetaData, uploadBankProof
} from "api";
import {
 handleApiError
} from "helpers";
import {
 LoaderContainer, Spinner
} from "../commons";

const defaultProps = {
  isPartnerFlow: false
};

const propTypes = {
  updateStatus: PropTypes.func.isRequired,
  isPartnerFlow: PropTypes.bool.isRequired,
  handleGoBack: PropTypes.func.isRequired
};

const BankProof = ({
  updateStatus,
  handleGoBack,
  isPartnerFlow,
}) => {

  const [isUploading, setIsUploading] = useState(false);
  const [isFetchingMetaData, setIsFetchingMetaData] = useState(false);
  const [BankProofMetaData, setBankProofMetaData] = useState(null);
  const [errorMessage, setErrorMessage] = useState();

  const handleSubmitFile = async (formData, docType) => {
    setErrorMessage(false);
    setIsUploading(true);
    try {
      const res = await uploadBankProof(formData, docType);
      if (res.status === 200) {
        updateStatus();
      } else if (res.status === 400) {
        if (res.status === 400) {
          res.json().then((data) => {
            setErrorMessage(data.detail);
          });
        }
      } else if (!res.ok) {
        throw {
          response: {
            status: 400,
            data: {
              detail: {
                message: "File Format Not Supported",
              },
            },
          },
        };
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsUploading(false);
    }
  };

  const fetchMetaData = async () => {
    setIsFetchingMetaData(true);
    try {
      const res = await getBankProofMetaData();
      setBankProofMetaData(res.data);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsFetchingMetaData(false);
    }
  };

  useEffect(() => {
    fetchMetaData();
  }, []);


  let uploadMessage = "Drag 'n' drop the chosen document here or click to select files<hr />";
  if (BankProofMetaData?.extras?.bank?.account) {
    uploadMessage += `<br /><div style="text-align: center; display: flex; flex-direction: column; justify-content: flex-start;"><b>Bank Account: ${BankProofMetaData?.extras?.bank?.account}</b>
    <b>IFSC Code: ${BankProofMetaData?.extras?.bank?.ifsc}</b></div>`;
  }

  return isFetchingMetaData ? (
    <LoaderContainer>
      <Spinner
        color="blue"
        message="Updating your information, Please do not close the window"
      />
    </LoaderContainer>
  ) : (
    <>
      {BankProofMetaData ? (
        <>
          {isPartnerFlow ? (
            <UploadPartnerDocumentContainer
              isLoading={isUploading}
              onSubmitDocument={handleSubmitFile}
              heading={BankProofMetaData?.pageTitle}
              subHeading={BankProofMetaData?.cardTitle}
              documentOptions={BankProofMetaData?.allowedBankProofs}
              errorMessage={errorMessage}
              uploadMessage={uploadMessage}
              goBackButton={{
                buttonText: "Go Back",
                callback: () => handleGoBack({'bank_proof': 'You have manually choose to reset.', 'penny_drop': 'You have manually choose to reset.'}),
                show: true,
                info: `<span>If you wish to update your bank account information, please go back and enter a new account number.
                  &nbsp;<img src=${IMAGES.infoIconThemeColor} alt="info-icon" title="Change bank account and IFFC code" /></span>`
              }}
            />
          ) : (
            <UploadDocumentContainer
              isLoading={isUploading}
              onSubmitDocument={handleSubmitFile}
              heading={BankProofMetaData?.pageTitle}
              subHeading={BankProofMetaData?.cardTitle}
              documentOptions={BankProofMetaData?.allowedBankProofs}
              errorMessage={errorMessage}
              uploadMessage={uploadMessage}
              goBackButton={{
                buttonText: "Go Back",
                callback: () => handleGoBack({'bank_proof': 'You have manually choose to reset.', 'penny_drop': 'You have manually choose to reset.'}),
                show: true,
                info: `<span>If you wish to update your bank account information, please go back and enter a new account number.
                  &nbsp;<img src=${IMAGES.infoIconThemeColor} alt="info-icon" title="Change bank account and IFFC code" /></span>`
              }}
            />
          )}
        </>
      ) : null}
    </>
  );
};

BankProof.prototype = propTypes;
BankProof.defaultProps = defaultProps;
export default BankProof;
