/**
Author - Venkatesh Pullaganti 😃
Git - https://github.com/venkatesh1146
Source- https://www.30secondsofcode.org/react/s/use-interval/
* */

import React, {
 useEffect
} from 'react';

/**
 * A hook implementation of useInterval
 *
 * @param callback Function to be called on interval
 *
 * @param delay in Milliseconds
 */
const useInterval = (callback, delay = 30000) => {
  const savedCallback = React.useRef(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const callbackExecuter = () => {
      savedCallback?.current && savedCallback?.current();
    };

    const id = setInterval(callbackExecuter, delay);
    return () => clearInterval(id);
  }, [delay]);
};

export default useInterval;
