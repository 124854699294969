/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React, {
useEffect
} from "react";
import Cookies from "js-cookie";
import {
ToastContainer
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
ACCESS_TOKEN_COOKIE_KEY,
SOURCE_KEY
} from "constants/cookie";
import {
isAuthenticated,
Analytics
} from "utils";
import env from "env";
import {
 ClientAppCommunicationContext, ClientAppCommsHandlers
} from "./context/CommunicationsContext";

import Router from "Router";

const App = () => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const authToken = searchParams.get("token");
  const redirectTo = searchParams.get("redirect_to");
  const newAppVersion = searchParams.get("new_app_version");
  const source = searchParams.get(SOURCE_KEY);

  useEffect(() => {
    Analytics.initializeSegment();
    if (source){
      Cookies.set(SOURCE_KEY, source);
    }
  }, []);

  if (!isAuthenticated()) {
    if (!authToken) {
      return (
        <h1>Page not Available</h1>
      );
    } else {
      let token = String(authToken).replace("%20", " ");
      Cookies.set(ACCESS_TOKEN_COOKIE_KEY, token);
      sessionStorage.setItem("redirectTo", redirectTo);
      sessionStorage.setItem("newAppVersion", newAppVersion);
      window.location.assign(env.WEALTHY_KYC_URL);
    }
  } else {
    if (isAuthenticated() && authToken && !!authToken.length) {
      let token = String(authToken).replace("%20", " ");
      Cookies.set(ACCESS_TOKEN_COOKIE_KEY, token);
      sessionStorage.setItem("redirectTo", redirectTo);
      sessionStorage.setItem("newAppVersion", newAppVersion);
      window.location.assign(env.WEALTHY_KYC_URL);
    } else {
      return (
        <>
        <head>
        <script>
            {(function (c, l, a, r, i, t, y) {
              c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments); };
              t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
              y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
            })(window, document, "clarity", "script", env.MS_CLARITY_CODE)}
            </script>
          </head>
          <ClientAppCommunicationContext.Provider
            value={new ClientAppCommsHandlers()}
          >
          <ToastContainer />
          <Router />
          </ClientAppCommunicationContext.Provider>
        </>
      );
    }
  }
};

export default App;
