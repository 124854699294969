/**
Author - Pradip Dhakal
Git - https://github.com/pradipwealthy
**/

import React from "react";
import Dropzone from "react-dropzone";
import parse from 'html-react-parser';

import PropTypes from "prop-types";

import {
    AttentionContainer,
    ActionWrapper,
    DeleteWrapper,
    DeleteIcon,
    DetailWrapper,
    Text,
    ImageSize,
    Wrapper,
    Title,
    ImageCard,
    PreviewImage,
    SubHeader,
    ImagesContainer,
    Container,
    UploadImage,
    UploadMessage,
    DropZoneContainer,
    DropContainer,
    InfoTextAndImg,
    OptionInfoText,
    InfoTextImg,
    ExtraSpace,
    EachOption,
} from "./UploadDocuments.style";

import {
    ActionButton,
    Dropdown
} from "screens/commons";
import IMAGES from "constants/Images";

/*
NOTE: Description of the props
commonInstructions: any instructions including html tags
actionButton: {
    buttonText: "text of the button",
    callback: "function to call when the button is clicked",
    isLoading: "boolean to show loading state",
    isDisabled: "boolean to disable the button"
}
documents: [
    {
        "heading": "heading of the document",
        "subHeading": "sub heading of the document",
        "documentOptions": allowed options for document,
        "selectedOption": selected document option, 
        "onChangeDocOption": callback fn for handling the selected option, 
        "uploadMessage": "message to show when the document is uploaded",
        "multiple": "boolean to allow multiple files",
        "name": "name of the document",
        "onDrop": "function to call when the document is dropped",
        "group": "group of the document" <- based on the group, the document will be shown in the same group
    }
]
handleDeleteUploadedFile - function to handle delete uploaded file
uploadedFiles - uploaded files
acceptFiles - supported file types format: {
    "mimeType": [".extension"],
}
*/
const propTypes = {
    commonInstructions: PropTypes.string,
    actionButton: PropTypes.object,
    documents: PropTypes.arrayOf(
        PropTypes.shape({
            heading: PropTypes.string.isRequired,
            subHeading: PropTypes.string.isRequired,
            documentOptions: PropTypes.arrayOf(
                PropTypes.shape({
                    key: PropTypes.string.isRequired,
                    text: PropTypes.string.isRequired,
                    render: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
                })
            ),
            selectedOption: PropTypes.shape({
                key: PropTypes.string.isRequired,
                text: PropTypes.string.isRequired,
                render: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
            }),
            onChangeDocOption: PropTypes.func,
            uploadMessage: PropTypes.string.isRequired,
            multiple: PropTypes.bool.isRequired,
            name: PropTypes.string.isRequired,
            onDrop: PropTypes.func.isRequired,
            group: PropTypes.string.isRequired,
        })
    ).isRequired,
    acceptFiles: PropTypes.object,
};


const UploadMultipleDocuments = ({
    commonInstructions = "",
    actionButton = {
        buttonText: "Upload & Continue",
        callback: () => { },
        isLoading: false,
        isDisabled: false,
    },
    documents = [],
    handleDeleteUploadedFile = () => { },
    uploadedFiles = [],
    acceptFiles = {
        "image/png": [".png"],
        "image/jpeg": [".jpg", ".jpeg"],
        "application/pdf": [".pdf"],
    }
}) => {

    const getOptions = (documentOptions, onChangeSelectedOption) => documentOptions?.map((eachDeclaration) => (
        <>
            <EachOption
                onClick={() => {
                    onChangeSelectedOption(eachDeclaration);
                }}
            >
                {parse(eachDeclaration?.render)}
            </EachOption>
        </>
    ));


    const groupedDocuments = documents.reduce((acc, doc) => {
        const group = doc.group;
        acc[group] = acc[group] || [];
        acc[group].push(doc);
        return acc;
    }, {});
    return (
        <>
            <Wrapper>
                {Object.keys(groupedDocuments).map((key) => {
                    const value = groupedDocuments[key];
                    return (
                        <div style={{marginBottom: "2rem"}}>
                            <Title>{value[0].heading}</Title>
                            <SubHeader dangerouslySetInnerHTML={{__html: value[0].subHeading}} />

                            {value[0]?.selectedOption ? <InfoTextAndImg>
                                <OptionInfoText className="pulsate-anim">
                                    <InfoTextImg src={IMAGES.infoIconThemeColor} />
                                    {parse(value[0].selectedOption.text)}</OptionInfoText>
                            </InfoTextAndImg>
                                : <ExtraSpace />}

                            {value[0].documentOptions?.length ? <Dropdown
                                options={getOptions(value[0].documentOptions, value[0].onChangeDocOption)}
                                title={value[0].selectedOption?.render ? parse(value[0].selectedOption?.render) : "Choose an option"}
                            /> : null}

                            <DropContainer>
                                {value?.map((item) => (
                                    <>
                                        <DropZoneContainer>
                                            <Dropzone
                                                onDrop={(file) => item?.onDrop(file, item.name)}
                                                multiple={item.multiple}
                                                accept={acceptFiles}
                                            >
                                                {({getRootProps, getInputProps}) => (
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <Container>
                                                                <UploadImage src={IMAGES.fileUploadImage} />
                                                            </Container>
                                                            <input {...getInputProps()} />
                                                            <UploadMessage dangerouslySetInnerHTML={{__html: item?.uploadMessage}} />
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </DropZoneContainer>
                                    </>
                                ))}
                            </DropContainer>
                            {value?.map(item => (
                                <ImagesContainer>
                                    {uploadedFiles?.map(
                                        (eachFile) =>
                                            [item.name].includes(eachFile?.id) && (
                                                <ImageCard>
                                                    <PreviewImage src={URL.createObjectURL(eachFile)} />
                                                    <DetailWrapper>
                                                        <Text>{eachFile.name}</Text>
                                                        <ImageSize>{eachFile.size * 0.001}Kb</ImageSize>
                                                    </DetailWrapper>
                                                    <ActionWrapper>
                                                        <DeleteWrapper
                                                            onClick={() => handleDeleteUploadedFile(eachFile)}
                                                        >
                                                            <DeleteIcon alt="delete image" src={IMAGES.deleteImage} />
                                                        </DeleteWrapper>
                                                    </ActionWrapper>
                                                </ImageCard>
                                            )
                                    )}
                                </ImagesContainer>
                            ))}
                        </div>
                    );
                })}

                {commonInstructions &&
                    <AttentionContainer>
                        <span>Attention:</span>
                        <div dangerouslySetInnerHTML={{__html: commonInstructions}} />
                    </AttentionContainer>
                }
                <br />
                <ActionButton
                    buttonText={actionButton?.buttonText}
                    callback={actionButton?.callback}
                    isLoading={actionButton?.isLoading}
                    isDisabled={actionButton?.isDisabled}
                />
            </Wrapper>
        </>
    );
};

UploadMultipleDocuments.propTypes = propTypes;

export default UploadMultipleDocuments;
