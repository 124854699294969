import React from 'react';

export default function useMSClarity() {
  const setClarityCustomTag = (key: string, value: string) => {
    try {
      if (typeof window !== 'undefined' && 'clarity' in window) {
        window?.clarity('set', key, value);
      }
    } catch (error) {
      console.log('Clarity error:', error);
    }
  };

  const sendClarityEvent = (event: string) => {
    try {
      if (typeof window !== 'undefined' && 'clarity' in window) {
        window?.clarity('event', event);
      }
    } catch (error) {
      console.log('Clarity event error:', error);
    }
  };

  // A custom id (userIdentifier) enables us to track the user across multiple devices and sessions
  const setClarityCustomId = (
    customId: string,
    customSessionId?: string,
    customPageId?: string,
    friendlyName?: string
  ) => {
    try {
      if (typeof window !== 'undefined' && 'clarity' in window) {
        window?.clarity(
          'identify',
          customId,
          customSessionId,
          customPageId,
          friendlyName
        );
      }
    } catch (error) {
      console.log('Clarity id error:', error);
    }
  };

  return {setClarityCustomTag, sendClarityEvent, setClarityCustomId};
}
