/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React from "react";
import PropTypes from "prop-types";

import {
  ActionButton,
  CustomInput,
  Spinner
} from "screens/commons";

import {
  PartnerBankDetailsWrapper,
  Container,
  Title,
  SubHeader,
  InputsContainer,
  ErrorMessage,
  IfscAddress,
  Header,
  HeaderText,
  FormContent,
  LoaderContainer,
  SubHeading,
  TitleWrapper,
SubTitleWrapper,
SubTitle
} from './PartnerBankDetails.styles';
import "./PartnerBankDetails.css";

const propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRedirectLoading: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isIfscValid: PropTypes.bool.isRequired,
  ifscResponse: PropTypes.object.isRequired,
  skipStep: PropTypes.func.isRequired,
  showSkipButton: PropTypes.object.isRequired
};

const PartnerBankDetails = ({
  onChangeHandler,
  onSubmit,
  formData,
  isLoading,
  isRedirectLoading,
  isDisabled,
  isIfscValid,
  ifscResponse,
  skipStep,
  showSkipButton
}) => (
  isRedirectLoading ? (
    <LoaderContainer>
      <Spinner
        color="blue"
        message="Updating your information, Please do not close the window"
      />
    </LoaderContainer>
  ) : (
    <PartnerBankDetailsWrapper>
      <Container>
        <FormContent>
          <Header>
          <TitleWrapper>
            <Title>Bank Details</Title>
            {/* {
              showSkipButton ? (
                <HeaderText onClick={skipStep}>Skip</HeaderText>
              ) : (
                <span onClick={skipStep} class="cross-button" />
              )
            } */}
            {/* <SubTitleWrapper>
              <SubTitle>Step 2/3</SubTitle>
            </SubTitleWrapper> */}
          </TitleWrapper>
            
          <SubHeading>Type in all your details correctly and recheck</SubHeading>
          </Header>
          <InputsContainer>
            <CustomInput
              label="Account Number"
              name="beneficiaryAccountNo"
              type="number"
              value={formData.beneficiaryAccountNo}
              onChange={onChangeHandler}
            />
            <CustomInput
              label="IFSC Code"
              name="beneficiaryAccountIfsc"
              value={formData.beneficiaryAccountIfsc}
              onChange={onChangeHandler}
              additionalStyles={{marginBottom: ifscResponse.address ? "-1rem": "1.5rem"}}
            >
              {
                !isIfscValid ? (
                  <ErrorMessage>Invalid IFSC Code, please check.</ErrorMessage>
                ) : (
                  <IfscAddress>{ifscResponse.address}</IfscAddress>
                )
              }
            </CustomInput>
          </InputsContainer>
        </FormContent>
        <ActionButton
          isDisabled={isDisabled}
          buttonText="Save"
          callback={onSubmit}
          isLoading={isLoading}
          className="partner-bank-action--button"
          additionalStyles={{
              // background: "#FFFFFF",
              // color: "rgb(103, 37, 244)",
              // border: "1px solid rgb(103, 37, 244)",
              // padding: "1.5rem",
              // marginTop: "1rem"
              // cursor: isDisabled ? "not-allowed" : "pointer"
            }}
        />
        <ActionButton
            isDisabled={isLoading}
            buttonText="Skip"
            callback={skipStep}
            className="partner-bank-action--button"
            additionalStyles={{
              background: "#FFFFFF",
              color: "rgb(103, 37, 244)",
              border: "1px solid rgb(103, 37, 244)",
              marginTop: "1rem",
            }}
          />
      </Container>
    </PartnerBankDetailsWrapper>
  ));

PartnerBankDetails.propTypes = propTypes;

export default PartnerBankDetails;
