/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  FlowType
} from "../../../enums";

import {
  Spinner,
  ActionButton
} from "../../commons";
import "./CheckDigiFlow.css";

const propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPanAadharSeeded: PropTypes.bool.isRequired,
  isFetchingPanAadharSeeded: PropTypes.bool.isRequired
};

const CheckDigiFlow = ({
  handleFormSubmit,
  isLoading,
  isPanAadharSeeded,
  isFetchingPanAadharSeeded
}) => {
  const loadingMessage = isFetchingPanAadharSeeded ? "Checking whether your Pan is linked with Aadhar Card, Please do not close the window" : "Updating your information, Please do not close the window";

  return isFetchingPanAadharSeeded || isLoading ? (
    <LoaderContainer>
      <Spinner
        color="purple"
        message={loadingMessage}
      />
    </LoaderContainer>
  ) : (
    <Container>
      <Wrapper>
      <TitleWrapper isMarginBelow>
        <Title>Please Select a KYC Option</Title>
        {/* <SubTitleWrapper>
          <SubTitle>Step 1/3</SubTitle>
        </SubTitleWrapper> */}
      </TitleWrapper>
        <ContentWrapper>
          {
            isPanAadharSeeded ? (
              <>
                <Card
                  onClick={() => handleFormSubmit(FlowType.Digilocker)}
                >
                  <Header>
                    <Title isMarginBelow>DigiLocker</Title>
                    <Badge>
                      Recommended
                    </Badge>
                  </Header>
                  <SubHeader>Select this option if you have access to the <br /> phone number linked with your Aadhar</SubHeader>
                  <ActionButton
                    buttonText="Choose Digilocker"
                    className="partner-check-flow--button"
                    callback={() => { }}
                    isDisabled={isLoading}
                    // additionalStyles={{
                    //   fontSize: "16px"
                    // }}
                  />
                </Card>
                <Break>
                  <HorizontalLine />
                  <Description>or</Description>
                  <HorizontalLine />
                </Break>
              </>
            ) : <></>
          }
          <Card
            onClick={() => handleFormSubmit(FlowType.Manual)}
          >
            <Title isMarginBelow>Manual KYC</Title>
            {
              isPanAadharSeeded ? (
                <SubHeader>
                  Select this option if you do not have access to the phone number linked with your Aadhar. Please have your PAN ready if you choose this option.
                </SubHeader>
              ) : <></>
            }
            <ActionButton
              buttonText="Choose Manual KYC"
              callback={() => { }}
              isDisabled={isLoading}
              className="partner-check-flow--button"
              
            />
          </Card>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  height: calc(100dvh - 18rem);
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wrapper = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.isMarginBelow ? '3rem' : '0rem'};
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 1px;
  padding: 0;
  color: #000000;
  ${'' /* letter-spacing: 0.1rem; */}
  align-self: flex-start;
  margin-bottom: ${props => props.isMarginBelow ? '1rem' : '0rem'};
`;

const SubTitleWrapper = styled.div`
  margin: 0px;
  padding: 4px 8px;
  border-radius: 2rem;
  color: rgb(28, 28, 28);
  border: 1px solid rgb(217, 217, 217);
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 12px
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const SubHeader = styled.p.attrs({
  className: 'description2'
})`
  margin: 0rem;
  padding: 0;
  color: #7E7E7E;
  align-self: flex-start;
  line-height: 19.6px;
`;

const Badge = styled.div.attrs({
  className: 'caption2'
})`
  color: #4FC16F;
  border: 1px solid #4FC16F;
  align-self: end;
  border-radius: 4px;
  padding: 4px 6px;
  margin-left: 1.5rem;
`;

const LoaderContainer = styled.div`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  ${'' /* background: #F8F4FF; */}

  ${'' /* height: 100vh; */}
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;
  border-radius: 2rem;

  img {
    width: 25px;
    height: unset
  }
`;

const Break = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 4rem 0rem;
  width: 80%;
`;

const HorizontalLine = styled.div`
  height: 1px;
  width: 100%;
  background: #E3E3E3;
`;

const Description = styled.div.attrs({
  className: 'description2'
})`
  color: #7E7E7E;
  margin: 0rem 2rem
`;

const Card = styled.div`
  width: 100%;
  margin-top :${props => props.marginTop || '0rem'};
  margin-bottom :${props => props.marginBottom || '0rem'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform .2s;
  align-items: center;
  background: #F8F4FF;
  border-radius: 1.5rem;
  padding: 2.25rem 2.25rem;
  cursor: pointer;
  max-height: 40rem;

  @media (max-width: 1200px) {
    width: 100%;
  }

  :hover {
    transform: scale(1.01);
  }
`;

CheckDigiFlow.propTypes = propTypes;

export default CheckDigiFlow;
