/**
Author - Vikrant Singh
Git - git@github.com:vikrantSingh97
**/

import React from "react";
import PropTypes from "prop-types";

import {
  ActionButton,
  CustomInput,
  Spinner
} from "screens/commons";

import {
  PartnerBankDetailsWrapper,
  ExtraSpace,
  Container,
  Title,
  SubHeader,
  InputsContainer,
  Header,
  LoaderContainer,
  SubHeading,
TitleWrapper,
SubTitleWrapper,
SubTitle
} from './PartnerGst.styles';
import "./PartnerGst.css";

const propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRedirectLoading: PropTypes.bool.isRequired,
  skipStep: PropTypes.func.isRequired,
  showSkipButton: PropTypes.bool.isRequired
};

const PartnerGst = ({
  onChangeHandler,
  onSubmit,
  formData,
  isLoading,
  isRedirectLoading,
  skipStep,
  showSkipButton
}) => (
  isRedirectLoading ? (
    <LoaderContainer>
      <Spinner
        color="blue"
        message="Updating your information, Please do not close the window"
      />
    </LoaderContainer>
  ) : (
    <PartnerBankDetailsWrapper>
      <Container>
        <Header>
          <TitleWrapper>
            <Title>Enter GST Number</Title>
            {/* <SubTitleWrapper>
              <SubTitle>Step 2/3</SubTitle>
            </SubTitleWrapper> */}
          </TitleWrapper>
          {
            showSkipButton ? (
              <SubHeader onClick={skipStep}>Skip</SubHeader>
            ) : null
          }
        <SubHeading>Please fill details below</SubHeading>

        </Header>
        <InputsContainer>
          <CustomInput
            label="Your GST Number"
            name="gstNo"
            type="text"
            value={formData.gstNo}
            onChange={onChangeHandler}
          />
          <ActionButton
            isDisabled={isLoading}
            buttonText="Proceed"
            callback={onSubmit}
            className="partner-gst-action--button"
          />
          <ActionButton
            isDisabled={isLoading}
            buttonText="Skip"
            callback={skipStep}
            className="partner-gst-action--button"
            additionalStyles={{
              background: "#FFFFFF",
              color: "rgb(103, 37, 244)",
              border: "1px solid rgb(103, 37, 244)",
              padding: "1.5rem",
              marginTop: "1rem"
            }}
          />
        </InputsContainer>
        <ExtraSpace />
      </Container>
    </PartnerBankDetailsWrapper>
  ));

PartnerGst.prototype = propTypes;

export default PartnerGst;